// export let API = "http://43.205.209.157:30009";
// export let BOOTCAMP_API = "http://bootcamp.futurekrux.com";

export let API = getAPI();
export let BOOTCAMP_API = getBootCampAPI();

export let NOTIFICATION_SERVICE = getNotoficationService();
export let EVENT_URL = getEventURL();

console.log('env-test' , process.env.REACT_APP_ENV_TYPE);

function getAPI() {

	if(process.env.REACT_APP_ENV_TYPE === "production"){
		return "https://api.machinehack.com";
	}
	else{
		return "https://machinehack.futurekrux.com";
	}
	
}

function getBootCampAPI() {

	if(process.env.REACT_APP_ENV_TYPE === "production"){
		return "https://bootcamp.prod.machinehack.com";
	}
	else{
		return "https://bootcamp.futurekrux.com";
		// return "http://localhost:1003";
	}
	
} 
function getNotoficationService() {

	if(process.env.REACT_APP_ENV_TYPE === "production"){
		return "https://api.machinehack.com/socket/";
	}
	else{
		return "http://43.205.209.157:30009/socket/";
		// return "http://localhost:1003";
	}
	
} 

function getEventURL() {

	if(process.env.REACT_APP_ENV_TYPE === "production"){
		return "https://machinehack.com";
	}
	else{
		return "https://machinehack-staging.netlify.com";
	}
	
} 

// if(process.env.REACT_APP_ENV_TYPE === "production")
// {
// 	API = "https://api.machinehack.com";
// 	BOOTCAMP_API = "https://bootcamp.prod.machinehack.com";
// }
// else
// {
// 	API = "http://43.205.209.157:30009";
// 	BOOTCAMP_API = "https://bootcamp.futurekrux.com";
// }

// if (localStorage.getItem("apiVar") === "Production") {
// 	console.log("Production");
// 	API = "https://api.machinehack.com";
// 	BOOTCAMP_API = "https://bootcamp.prod.machinehack.com";
// } else {
// 	console.log("Development");
// 	API = "http://43.205.209.157:30009";
// 	BOOTCAMP_API = "https://bootcamp.futurekrux.com";
// }

